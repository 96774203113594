import React from 'react'
import Layout from '../components/layout'
import NavigationBar from "../components/navigation-bar"
import Seo from "../components/seo"
import MenuButton from "../components/menu-button"
import Menu from "../components/menu"
import Footer from '../components/footer'
import skills from '../content/skill-content'
import { SkillCard } from "../components/card"
import { Link } from 'gatsby'
import '../styles/layout.styles.sass'
import '../styles/navigation.styles.sass'
const SkillPage = () => {
    const [isOpen, setOpen] = React.useState(false)

    return (
        <Layout>
            <Seo title="My skills" description ="Know more about tools and technologies that I am proficient in." meta="java, kotlin, javascript, python, typescript, microservices, kafka, mongodb, cloud, aws, nodejs, machine learning, chatbots, ai, ml, spring boot, firebase, google, heroku, angular, react, flutter, devops." />
            <MenuButton page={'skill-page-bar'} open={isOpen} onClickOperation={setOpen} />
            <Menu open={isOpen} onCloseOperation={setOpen} />
            <div className={`page-container skill-page-container ${!isOpen ? 'page-container-open' : 'page-container-close'}`}>
                <div className={'navigation-container-left'}>
                    <Link to="/"><NavigationBar title={'About me'} page={'about-me'} /></Link>
                </div>
                <div className={'content-container'}>
                    <div className={'layout-container'}>
                        <div className={'title skill-title'}>
                            <em>My skills</em>
                        </div>
                        <div className={'skill'}>
                            {
                                skills.map((item, index) => {
                                    return <div className={'item'} key={item.title}>
                                        <SkillCard skills={item.items} title={item.title} key={index}/>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                    <Footer />
                </div>
                <div className={'navigation-container-right'}>
                    <Link to="/blog"> <NavigationBar title={'Blogs'} page={'blogs'} /></Link>
                    <Link to="/projects"> <NavigationBar title={'Projects'} page={'projects'} /></Link>
                    <Link to="/contact"><NavigationBar title={'Contact me'} page={'contact-me'} /></Link>
                </div>
            </div>
        </Layout>
    )

}

export default SkillPage