const technicalItems = [
    {
        left: 'Backend',
        right: 'Frontend'
    },
    {
        left: 'DevOps',
        right: 'Architecture'
    }
]

const languages = [
    {
        left: 'Java',
        right: 'Kotlin',
    },
    {
        left: 'Javascript',
        right: 'Python',
    },
    {
        left: 'Typescript',
    }
]

const backendItems = [
    {
        item: 'Spring-boot (Java, Kotlin)',
        isSingle: true,
    },
    {
        item: 'Nodejs (Javascript, Typescript)',
        isSingle: true,
    },
]

const frontendItems = [
    {
        left: 'ReactJs',
        right: 'NextJs'
    },
    {
        left: 'GatsbyJs',
        right: 'Vue.js',
    },
]

const mlItems = [
    {
        left: 'Chatbots',
        right: 'NLP'
    },
    {
        left: 'Entity classification',
    },
]

const cloudItems = [
    {
        left: 'AWS',
        right: 'GCP'
    },
    {
        left: 'Firebase',
        right: 'Heroku',
    },
]

const archItems = [
    {
        item: 'Microservices with REST',
        isSingle: true,
    },
    {
        item: 'Microservices with Kafka',
        isSingle: true,
    },
    {
        item: 'Serverless design',
        isSingle: true,
    },
]

const deploymentItems = [
    {
        left: 'Tomcat',
        right: 'Docker'
    },
    {
        left: 'Kubernetes',
        right: 'Heroku',
    },
]

const testingItems = [
    {
        left: 'Cypress.io',
        right: 'JUnit'
    },
    {
        left: 'Spock',
        right: 'Cucumber',
    },
    {
        item: 'Postman test runner',
        isSingle: true
    },
]

const skills = [
    {
        title: 'Technical',
        items: technicalItems
    },
    {
        title: 'Languages',
        items: languages
    },
    {
        title: 'Backend',
        items: backendItems
    },
    {
        title: 'Frontend',
        items: frontendItems
    },
    {
        title: 'Testing',
        items: testingItems
    },
    {
        title: 'Machine learning',
        items: mlItems
    },
    {
        title: 'Cloud',
        items: cloudItems
    },
    {
        title: 'Architechture',
        items: archItems
    },
    {
        title: 'Deployment',
        items: deploymentItems
    },
]

export default skills